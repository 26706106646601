import React from 'react';
import * as styles from './double-image.module.scss';

interface DoubleImageProps {
  firstImage: string;
  secondImage: string;
}

export default function DoubleImage(props: DoubleImageProps): JSX.Element {
  return (
    <div className={styles.content}>
      <img src={props.firstImage} />
      <img src={props.secondImage} />
    </div>
  );
}
