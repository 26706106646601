import React from 'react';
import '../i18n/i18n';
import HeaderBloc from '../components/bloc/header-bloc/headerBloc';
import Image from '../components/bloc/image/image';
import Information from '../components/bloc/information/information';
import LayoutBloc from '../components/bloc/layout-bloc/layoutBloc';
import case2Image1 from '../assets/case2_img_1@2x.webp';
import case2Image2 from '../assets/case2_img_2@2x.webp';
import case2Image3 from '../assets/case2_img_3@2x.webp';
import case2Image4 from '../assets/case2_img_4@2x.webp';
import case2Image5 from '../assets/case2_img_5@2x.webp';
import case2Image6 from '../assets/case2_img_6@2x.webp';
import Layout from '../components/layout';
import DoubleImage from '../components/bloc/double-image/double-image';

export default function RedisigningInformationArchitecture(): JSX.Element {
  return (
    <Layout>
      <LayoutBloc>
        <HeaderBloc
          title="USE_CASE_2.BLOC_TITLE"
          description="USE_CASE_1.DESCRIPTION"
          info="Research / UX / UI / mobile"
          backgroundColor="#D1E8F4"
          style={{ backgroundColor: '#D1E8F4' }}
          img={case2Image1}
        />
        <div style={{ maxWidth: '1080px', margin: 'auto' }}>
          <Information title="USE_CASE_2.CHALLENGE_TITLE" text={['USE_CASE_2.CHALLENGE_TEXT']} />
          <Image title="USE_CASE_2.DESIGN_PROCESS" style={{ backgroundColor: '#F4F5FA' }} src={case2Image2} />
          <Information title="USE_CASE_2.CONCEPTION_TITLE" text={['USE_CASE_2.CONCEPTION_TEXT']} />
          <DoubleImage firstImage={case2Image3} secondImage={case2Image4} />
          <Information
            title="USE_CASE_2.INSIGHTS_TITLE"
            text={[
              'USE_CASE_2.INSIGHTS_1',
              'USE_CASE_2.INSIGHTS_2',
              'USE_CASE_2.INSIGHTS_3',
              'USE_CASE_2.INSIGHTS_4',
              'USE_CASE_2.INSIGHTS_5',
            ]}
          />
          <Image title="USE_CASE_2.EARLY_PROTOTYPES" style={{ backgroundColor: '#D1E8F4' }} src={case2Image5} />
          <Information
            title="USE_CASE_2.TEST_TITLE"
            text={['USE_CASE_2.TEST_1', 'USE_CASE_2.TEST_2', 'USE_CASE_2.TEST_3']}
          />
          <Image
            title="USE_CASE_2.VALIDATED_DESIGN"
            style={{ backgroundColor: '#FAF3D5', height: '461px' }}
            src={case2Image6}
          />
          <Information
            title="USE_CASE_2.RESULT_LEARNINGS_TITLE"
            text={['USE_CASE_2.RESULT_LEARNINGS_1', 'USE_CASE_2.RESULT_LEARNINGS_2']}
          />
        </div>
      </LayoutBloc>
    </Layout>
  );
}
